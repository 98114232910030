.navbar-brand-full {
  position: relative;
  left: 20px;
}

.btn.cursor-pointer {
  cursor: pointer;
}

.cal-day-selected {
  -webkit-box-shadow: 0 0 6px 4px #2999d0;
  -moz-box-shadow: 0 0 6px 4px #2999d0;
  box-shadow: 0 0 6px 4px #2999d0;
  z-index: 999;
}

.cal-day-badge {
  display: none !important;
  background-color: #2999d0 !important;
}

.cal-out-month,
.cal-out-month:hover {
  opacity: 1;
  background-color: #ddd !important;
  pointer-events: none;
}

.cal-month-view .cal-day-cell {
  min-height: 80px !important;
}

.fix-ri {
  position: relative;
  top: 2px;
}