$enable-negative-margins: true;
$primary-base: #115db0 !default;
$nav-tabs-link-active-bg: #fff;
$header-height: 50px;

$card-bg: #fff;
$table-bg: #fff;
$list-group-bg: #fff;
// REFCLAT BLAU
$primary: #004FA0;

// If you want to override variables do it here
@import "variables";
// Create your own map

:root {

  --main-bg-color: #{$body-bg};
  --main-header-height: #{$header-height};
  --cui-table-bg: #fff !important;
}



.bg-body {
  background-color: #{$body-bg};
}


/* You can add global styles to this file, and also import other style files */
@import "../../node_modules/@coreui/coreui/scss/coreui";
@import "../../node_modules/@reflact/ngx-forms/src/lib/styles.scss";

// If you want to add something do it here
@import "custom";
@import "../../node_modules/remixicon/fonts/remixicon.css";
@import "../../node_modules/angular-calendar/css/angular-calendar.css";
@import "../../node_modules/ngx-popperjs/css/theme-dark.css";


.wrapper {
  padding-left: var(--cui-sidebar-occupy-start, 0);
}

.tooltip {
  left: 0.8% !important;
}

body,
app-dashboard,
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;
}

.app-body .main {
  flex: 1;
  min-width: 0;
}

.sticky-submenu {
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 999;
}

.form-floating .ng-select.ng-select-single .ng-select-container {
  height: 20px;
}

.form-floating .ng-select .ng-select-container {
  min-height: 20px;
  border: none;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #D9D8Df !important;
}

.form-floating .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-left: 0;
}

.form-floating>.ng-select.form-control,
.form-floating>.form-select {
  min-height: calc(3.5rem + 2px) !important;
  height: inherit !important;
  line-height: 1.25;
}

.ng-select [class^="ri"] {
  position: relative;
  top: 3px;
}

.sticky-quicklook .datasupply-form-body {
  border-top: 0px solid #cfd2d9;

  border-bottom: 0px solid #cfd2d9;
  padding: 2px;

  max-height: calc(100vh - 295px);
  margin-bottom: 5px;
  overflow-y: auto;
}



@media (max-width: 575px) {
  .sticky-quicklook .datasupply-form-body {
    max-height: inherit;
    overflow-y: auto;
  }
}

.table td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.table th {
  border-bottom: 1px solid #000;
}

.table tr {
  border-bottom: 1px solid #EFEFEF;
}


tr.table-active,
.nav-link.active {
  box-shadow: 4px 0px inset $primary;

}

.btn-view-switcher {
  padding: 10px 20px;
  text-transform: uppercase;
  letter-spacing: 1.25px;

  border: 0px;
  border-radius: 0px;

  &:hover {

    background-color: #F9F9F9;
    color: $primary;
  }

  &.btn-primary {
    background-color: #fff;

    color: $primary;
    box-shadow: 0px -4px inset $primary;
  }
}



/* .base-view-switcher {
  border-bottom: 1px solid #D9D8D9;
  margin-bottom: 10px;
} */
#sidebar {

  z-index: 1030;

  .sidebar-nav .nav-title {
    text-transform: none;
  }

  .nav-link:hover {
    background-color: #EFEFEF88;

  }

  box-shadow: 0px 0px 25px -15px #000000;
  //border-right: 1px solid #CFD2D9;
  background-color: #ffffff;

  .active,
  .active.nav-link-icon {
    background-color: #eff5fa;
    color: $primary;
  }

  * {
    color: #000;
  }
}



/* .sticky-quicklook { top:185px !important} */
.form-control {
  background-color: #1a224405;
}

.tooltip-arrow {
  display: none !important;
}

.content-header {
  z-index: 1500
}




.sidebar-backdrop.show {
  z-index: 999
}

.modal {
  z-index: 3502
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* .grid-table {
  border:1px solid red;
  overflow: scroll;
  height: calc(100vh - 380px);

} */



.maincontent {
  border: 0px solid $primary;
  height: 100%;
  overflow-y: scroll;
  padding-left: $spacer * 1.5;
}

.innertest {
  position: fixed;
  background-color: red;

}

.header {
  background-color: red;
}

.sticky-quicklook {
  border: 1px solid $primary
}

.content-header h3 {
  margin: 0px;
}

.content-grid {

  grid-template-rows: auto 1fr;
  height: 100%;
  // NEVER EVER BE GRID HERE
  /* display: grid; */
  grid-template-areas: 'header' 'content';
}



.content-grid-header {
  grid-area: header;
  top: 0px;
  background-color: $body-bg;
  z-index: 15;

  * {
    color: #000;
  }


}

.content-grid-content {

  grid-area: content;
  overflow: auto;

}

.datasupply-table tr th {
  padding-left: calc($spacer *1.5);
}

.datasupply-table tr td {
  padding-left: calc($spacer *1.5);
}

.aside-grid {
  .table tr td {
    padding-left: calc($spacer *1.5);
  }

  .table tr th {
    padding-left: calc($spacer *1.5);
  }

  /* padding-left:calc($spacer *1.5); */
}

/* .aside-grid-quicklook { padding:0px calc($spacer *1.5);} */
.aside-grid-quicklook {
  border-left: 1px solid #CFD2D9;
}

.quicklook-header {
  border-bottom: 1px solid #000;
  padding-top: 5px;

  .quicklook-title {
    color: #141935;
  }


  padding-bottom: 0px !important;
}


.aside-grid-quicklook-form-grid-header {
  background-color: #EBEBEB;
  padding: 4px 0px 0px 0px;
}



.aside-grid-quicklook-form-grid-body {
  padding: calc($spacer*1.5)
}

.quicklook-title {
  font-weight: bold;
  padding-left: calc($spacer*1.5);
}

.tab-area-content-wrapper {
  padding: calc($spacer*1.5);
}


$cta: $primary-base;
$custom-variants: (
  "cta":btn-color-map($cta, $cta)
);
$custom-variants-outline: (
  "cta":btn-outline-color-map($cta)
);
$button-variants: map-merge($button-variants, $custom-variants );
$button-outline-ghost-y: map-merge($button-outline-ghost-variants, $custom-variants-outline );

@each $state,
$variant in $button-variants {
  .btn-#{$state} {
    @include button-variant($variant);
  }
}

@each $state,
$variant in $button-outline-ghost-variants {
  .btn-outline-#{$state} {
    @include button-outline-variant($variant);
  }
}

@each $state,
$variant in $button-outline-ghost-variants {
  .btn-ghost-#{$state} {
    @include button-ghost-variant($variant);
  }
}

.btn-outline-cta:not(:hover) * {
  color: #888;
}

.btn-cta,
.btn-outline-cta {
  border-radius: 90px;
  margin-right: 2px;
  margin-bottom: 1px;
}

.component-table th,
.component-table td {
  padding-left: calc($spacer*1.5);

}

.clear-asside-padding tr td,
.clear-asside-padding tr th {
  padding-left: $spacer !important;
}

#toast-container>div {
  max-width: 500px;
  width: auto;
}

.cursor-pointer {
  cursor: pointer
}

.bg-cta {
  /* background-color: #8dbceb !important; */
  background-color: var(--cui-primary);
}


.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0px !important;

}

.btn-logout {
  color: $primary !important;
}

.btn-logout:hover {
  color: white !important;
}




.lib-rag-ds-grid {
  /**
   * User input values.
   */
  --grid-layout-gap: 40px;
  --grid-column-count: 50;
  --grid-item--min-width: 250px;

  /**
   * Calculated values.
   */
  --gap-count: calc(var(--grid-column-count) - 1) !important;
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap)) !important;
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count)) !important;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)) !important;
  grid-gap: var(--grid-layout-gap) !important;
}

.lib-rag-ds-grid .card {
  display: flex;
  flex-direction: column;
  height: 100%;
}










.btn-outline-primary.text-primary:hover {
  color: #fff !important
}

body.overlay-asside-isopen {
  overflow: hidden;
}

.lib-rag-ds-grid .card .card-footer {
  background-color: transparent !important;
}


.lib-rag-ds-grid .card:hover {
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 21, 0.3) !important;
  transition: all 0.2s;
}

.lib-rag-ds-grid .card:hover .card-btn:hover {
  background-color: #F3F4FA !important;
}

.text-smaller {
  font-size: smaller;
  line-height: 0.8rem;
}

/*
zum debuggen
body{
  border-top:20px dashed red;}

@include media-breakpoint-up(sm) {
  body{ border-top:20px solid red;}

 }
 @include media-breakpoint-up(md) {
  body{ border-top:20px solid green;}

 }
 @include media-breakpoint-up(lg) {
  body{ border-top:20px solid blue;}

 }
 @include media-breakpoint-up(xl) {
  body{ border-top:20px solid cyan;}
 }
 @include media-breakpoint-up(xxl) {
  body{ border-top:20px solid magenta;}
 }
 */


.mainrow {


  &.even {
    background-color: #EFF4FA;

    & .row.even {
      background-color: #E2E7EB;
    }

    & .row.odd {
      background-color: #EFF4F9;
    }
  }

  &.odd {
    background-color: #fff;

    & .row.even {
      background-color: #F2F2F2;
    }
  }
}

.subScale #fe {
  top: 17px !important
}

.subScale #meanLine {
  top: 17px !important
}

.subScale #feLine {
  top: 17px !important
}

.mainrow.isopen .colC {
  padding: 0px;

}

.card-btn {
  background-color: transparent !important;
  color: var(--cui-primary) !important;
}

.card-btn .card-btn-inner-content {
  display: flex;
  align-items: center;
}

.card-btn i {
  font-size: 1.2rem;
}

.mainrow.isopen .rowForcePerspective {
  box-shadow: inset 4px 0px 0px 0em #3879FE !important;
}

.mainrow.isopen {
  padding-bottom: 40px;

  .force-group {
    margin-bottom: 20px !important;
    align-items: start !important;
  }
}

@include media-breakpoint-up(xl) {

  .mainrow.isopen .rowForcePerspective {
    box-shadow: none !important;
  }
}


.mainrow.isopen .seheader .rowForcePerspective {
  box-shadow: none !important;
}

:root {
  --main-bg-color: #{$body-bg};
  --main-header-height: #{$header-height};

}

/* .table {
  --#{$prefix}table-bg: #fff;
}
 */

 .line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp:  var(--lines);/* number of lines to show */
          line-clamp:  var(--lines);
  -webkit-box-orient: vertical;
}


@import "./print.scss";