/**********
**
** here we can predefine everything 
** @import "../../node_modules/@coreui/coreui/scss/coreui/varaibles";
**
** EXAMPLE:
** $blue:    orange !default;
** $body-bg:                   $blue !default;
************/

$body-bg:  #F3F4FA;

$sidebar-width:                            16rem !default;





