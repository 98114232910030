@media print {
.ms-print-5 {
  margin-left: 3rem !important;
}
.me-print-n5{
  margin-right: -2rem !important;
}

  .gallery-grid {
    .card.shadow.border-0.card-extra-round {
      padding: 0px !important;
    }
  }


  .mainrow.isopen .force-group {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
  }

  .removePrintShadow {
    box-shadow: none !important;
  }

  .mainrow {

    border-bottom: 0px !important;

    &.even,
    &.odd {
      background-color: #fff;

      & .row.even {
        background-color: #E2E7EB;
      }

      & .row.odd {
        background-color: #EFF4F9;
      }
    }

  }




  .sticky-print-none {
    position: inherit !important;
  }

  .shadow {
    border: 0px solid red !important;
    box-shadow: none !important;
  }

  * {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  .fixed-top {
    position: static !important;
  }

  .toast-container {
    display: none;
  }

  .break {
    page-break-after: always !important;
  }

  .d-block-print {
    display: block !important;
  }

  .wrapper {

    padding-top: 1cm !important;
    padding-right: 1.5cm !important;
    padding-left: 1.5cm !important;

    & .container-fluid {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .content-grid-header,
  .page-header,
  body {
    background-color: #fff !important;
  }



  .force-group {
    page-break-inside: avoid !important;
  }


  .mainrow.isopen {
    box-shadow: none !important;
  }

  .mainrow.isopen .rowForcePerspective {
    box-shadow: none !important;
  }

  .border-print-1 {
    border: 1px solid #c0c0c0;
  }

  .rowForcePerspective {
    border: 0px solid red !important;
  }

  .mainrow.even {
    background-color: #fff !important;
  }

  .groupQuestionsRow-text {
    color: red !important;
    /* margin: 0px  !important;
    padding: 0px !important; */
  }

  /*   .mainrow::before{
content: 'LALALAALAAL';
    height: 300px;
    page-break-before: always;
    background-color: red;
  }

 */
  .print-groupname {
    margin-left: calc(var(--cui-gutter-x) * -0.5) !important;
    margin-right: calc(var(--cui-gutter-x) * -0.5) !important;
    text-align: left;
    border-bottom: 2px solid #000;

    display: block;
    padding-left: 0px !important;

    padding-top: 0.5cm !important;
    padding-bottom: 0.5cm !important;
    margin-top: 0.5cm !important;

    font-size: larger;
  }

}
